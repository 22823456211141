<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="logotypes/baselinker_36.svg"
            headerText="Setup Your BaseLinker Collect"
            @continue="continueForm"
            @reset="resetForm"
            @input="onFormInput"
            @update="onFormUpdate"
            ref="tabbedForm"
            @finish="step => (isDetails ? updateCollect(step) : createCollect(step))"
            :details="isDetails"
            :dirty="dirty"
            :fetched="fetched"
            :update="updateCollect"
            :update-redirect="updateRedirect"
        >
            <template v-slot:step-2-feedback>
                <b-row>
                    <b-col>
                        <Feedback
                            v-if="feedbackError"
                            :state="feedbackError.state"
                            :invalid="feedbackError.message"
                        ></Feedback>
                    </b-col>
                </b-row>
            </template>
        </wit-tabbed-form>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {collectMixin} from '@/mixins/collectMixin'
import {formMixin} from '@/mixins/formMixin'
import {filterDirty} from '@/shared/filterDirty.js'

const cloneDeep = require('lodash.clonedeep')

export default {
    components: {
        Feedback,
        Loading,
        WitTabbedForm,
    },
    data() {
        return {
            collectForm: {
                name: '',
                tokenId: null,
                settings: [],
            },
            workflowForm: {
                historicalDateRange: 30,
            },
            currentCollectForm: {},
            settingsOptions: [
                {id: 'collectUserLogin', label: 'Collect user login'},
                {id: 'collectPhone', label: 'Collect phone'},
                {id: 'collectEmail', label: 'Collect email'},
                {id: 'collectDeliveryFullname', label: 'Collect delivery full name'},
                {id: 'collectInvoiceFullname', label: 'Collect invoice full name'},
                {id: 'collectInvoiceAddress', label: 'Collect invoice address'},
                {id: 'collectDeliveryAddress', label: 'Collect delivery address'},
                {id: 'collectDeliveryPointAddress', label: 'Collect delivery point address'},
            ],
            feedbackError: null,
        }
    },
    mixins: [collectMixin, formMixin, validationMixin],
    validations: {
        collectForm: {
            name: {required},
            tokenId: {required},
            settings: {},
        },
        workflowForm: {
            historicalDateRange: {},
        },
    },
    created() {
        if (!this.isDetails) {
            this.collectForm.settings = this.settingsOptions.map(el => el.id)

            const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
            const formSessionId = formSessionMap[this.$route.path].id

            this.$gtm.trackEvent({
                event: 'create_resource_start',
                action: 'create',
                value: formSessionId,
            })
        } else {
            this.fetchCollect()
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            loading: 'loading/state',
        }),
        isDetails() {
            return Boolean(this.$route.params.id)
        },
        cannotContinue() {
            return this.isDetails
        },
        formId() {
            return this.isDetails ? 'baselinker-create-form' : 'baselinker-details-form'
        },
        steps() {
            return [
                {
                    name: 'Collect Settings',
                    invalid: this.$v.collectForm.$invalid,
                    inputs: [
                        {
                            name: 'name',
                            value: this.$v.collectForm.name.$model,
                            model: this.$v.collectForm.name,
                            type: 'text',
                            inputLabel: 'Collect name',
                            placeholder: 'Enter your collect name',
                            invalid: 'This field is required',
                            valid: 'Name is valid',
                        },
                        {
                            name: 'token',
                            value: this.$v.collectForm.tokenId.$model,
                            model: this.$v.collectForm.tokenId,
                            type: 'baselinker-token',
                            inputLabel: 'Select BaseLinker token',
                            disabled: this.isDetails,
                            preselectFirst: true,
                        },
                        {
                            name: 'settings',
                            value: this.$v.collectForm.settings.$model,
                            model: this.$v.collectForm.settings,
                            type: 'treeselect',
                            inputLabel: 'Advanced settings',
                            placeholder: 'Select advanced settings',
                            options: this.settingsOptions,
                            beforeClearAll: () => (this.collectForm.settings = []),
                            multiple: true,
                            valueFormat: 'id',
                            valueConsistsOf: 'LEAF_PRIORITY',
                            isAdvanced: true,
                        },
                    ],
                },
                this.isDetails
                    ? null
                    : {
                          name: 'Data Collection Settings',
                          invalid: this.$v.$invalid,
                          inputs: [
                              {
                                  name: 'range',
                                  value: this.$v.workflowForm.historicalDateRange.$model,
                                  model: this.$v.workflowForm.historicalDateRange,
                                  type: 'range',
                                  inputLabel: 'Collect data for last (days)',
                                  min: 0,
                                  max: 365,
                              },
                          ],
                      },
            ].filter(Boolean)
        },
        dirty() {
            const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
            return Object.keys(collectFormDirty).length > 0
        },
        baseSettings() {
            return this.settingsOptions.reduce((acc, curr) => ({...acc, [curr]: false}), {})
        },
    },
    methods: {
        async fetchCollect() {
            this.$store.commit('loading/PROCESSING', `Fetching...`)

            const response = await this.axios.get(
                `${process.env.VUE_APP_NODE_API_HOST}/collect/baselinker/${this.$route.params.id}`
            )
            this.collect = response.data.data
            this.collectForm = {
                name: this.collect.name,
                tokenId: this.collect.tokenRef.id,
                settings: Object.keys(this.collect.settings).reduce((acc, curr) => {
                    if (this.collect.settings[curr]) {
                        return [...acc, curr]
                    } else return acc
                }, []),
            }

            this.currentCollectForm = cloneDeep(this.collectForm)
            this.fetched = true

            this.$store.commit('loading/PROCESSED')
        },

        async updateCollect() {
            if (!this.$v.$invalid) {
                const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
                if (Object.keys(collectFormDirty).length > 0) {
                    const {settings, ...dirty} = collectFormDirty

                    const oldSettings = this.currentCollectForm.settings.reduce(
                        (acc, curr) => ({...acc, [curr]: false}),
                        {}
                    )

                    const data = {
                        ...dirty,
                        settings: settings
                            ? {
                                  ...oldSettings,
                                  ...this.collectForm.settings.reduce((acc, curr) => ({...acc, [curr]: true}), {}),
                              }
                            : undefined,
                    }

                    this.$store.commit('loading/PROCESSING', `Updating...`)
                    try {
                        await this.axios.post(
                            `${process.env.VUE_APP_NODE_API_HOST}/collect/shoper/${this.$route.params.id}`,
                            data
                        )

                        this.feedbackError = {}
                        this.currentCollectForm = cloneDeep(this.collectForm)
                        this.$forceUpdate()
                        this.$v.$reset()

                        this.$store.commit('loading/PROCESSED')
                    } catch (exception) {
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                        this.$errorHandler.report(exception, 'Could not update form')
                    }
                }
            }
        },

        async createCollect(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                this.feedbackError = {
                    state: null,
                    message: '',
                }

                const data = {
                    name: this.collectForm.name,
                    tokenId: this.collectForm.tokenId,
                    settings: this.collectForm.settings.reduce((acc, curr) => ({...acc, [curr]: true}), {}),
                    ...this.workflowForm,
                }

                this.$store.commit('loading/PROCESSING', `Creating your Collect...`)
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/collect/baselinker`, data)
                    .then(response => {
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {}
                        this.resetForm()

                        const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                        const formSessionId = formSessionMap[this.$route.path].id

                        this.$gtm.trackEvent({
                            event: 'create_resource_finish',
                            action: 'finish',
                            value: formSessionId,
                        })

                        formSessionMap[this.$route.path] = null
                        localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                        this.$projectRouter.push(this.redirectUrl)
                    })
                    .catch(exception => {
                        this.$errorHandler.report(exception, 'Could not create form')
                        this.$store.commit('loading/PROCESSED')
                        this.feedbackError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                    })
            }
        },
    },
}
</script>

<style lang="scss"></style>
